import ClassNames       from 'classnames';
import PropTypes        from 'prop-types';
import React            from "react";

import BaobabComponent  from './BaobabComponent';

import API              from '../js/API';
import Data             from '../js/Data';

export default class Instruction extends BaobabComponent {
    static propTypes = {
        id: PropTypes.string.isRequired
    };

    stateQueries() {
        const {id: sId} = this.props;

        return {
            loading: {
                cursor:  BaobabComponent.LOCAL_STATE,
                default: false
            },
            instruction: {
                cursor:   ['local', 'instructions', sId],
                setState: oState => {
                    oState.changed     = Data.isDiff(['local', 'instructions', oState.instruction.id]);
                    oState.is_archived = oState.instruction.status === 'Archived';
                }
            }
        }
    }

    componentWillReceiveProps(oNextProps) {
        if (oNextProps.id !== this.props.id) {
            const {id: sId} = oNextProps;

            this.overrideCursor('instruction', {
                cursor:   ['local', 'instructions', sId]
            });
        }
    }

    render() {
        const {
            instruction: oInstruction,
            is_archived: bArchived
        } = this.state;

        if (!oInstruction) {
            return null;
        }

        return (
            <div className="ui form">
                <div className="field">
                    <label>Name</label>
                    <input name="name" value={oInstruction.name} onChange={this.setProperty} readOnly={bArchived} />
                </div>

                <div className="field">
                    <label>Text</label>
                    <textarea name="text" value={oInstruction.text || ''} placeholder="Add the instructions that should be sent to the vendor" onChange={this.setProperty} readOnly={bArchived}/>
                </div>

                {this.renderLinks()}
                {this.renderArchive()}
            </div>
        );
    }

    renderLinks() {
        const {
            changed:     bChanged,
            is_archived: bArchived
        } = this.state;

        if (bArchived) {
            return null;
        }

        return (
            <div className="field">
                <div className="ui two buttons">
                    <div className={ClassNames("ui positive icon button", {disabled: !bChanged})} onClick={this.save}><i className="save icon"/> Save</div>
                    <div className={ClassNames("ui icon button", {disabled: !bChanged})} onClick={this.reset}><i className="erase icon"/> Reset</div>
                </div>
            </div>
        )
    }

    renderArchive() {
        const {
            is_archived: bArchived,
        } = this.state;

        if (bArchived) {
            return (
                <div className="field">
                    <div className="ui right floated basic negative icon button" onClick={this.reactivate}><i className="archive outline icon"/> Reactivate</div>
                </div>
            )
        }

        return (
            <div className="field">
                <div className="ui right floated negative icon button" onClick={this.archive}><i className="archive icon"/> Archive</div>
            </div>
        )
    }

    setProperty = oEvent => {
        this.CURSORS.instruction.set(oEvent.target.name, oEvent.target.value);
    };

    save = () => {
        const {instruction: oInstruction} = this.state;
        API.post(`/instructions/${oInstruction.id}`, oInstruction, (oError, oResponse) => Data.mergeResponse(oResponse));
    };

    reset = () => {
        const {instruction: oInstruction} = this.state;
        this.CURSORS.instruction.merge(Data.Base.get(['server', 'instructions', oInstruction.id]));
    };

    archive = () => {
        this.CURSORS.instruction.merge({status: 'Archived'});
        Data.Base.commit();
        const oInstruction = Data.Base.get(['local', 'instructions', this.props.id]);
        API.post(`/instructions/${oInstruction.id}`, oInstruction, (oError, oResponse) => Data.mergeResponse(oResponse));
    };

    reactivate = () => {
        this.CURSORS.instruction.merge({status: 'Active'});
        Data.Base.commit();
        const oInstruction = Data.Base.get(['local', 'instructions', this.props.id]);
        API.post(`/instructions/${oInstruction.id}`, oInstruction, (oError, oResponse) => Data.mergeResponse(oResponse));
    };
}