import ClassNames       from 'classnames';
import DateFormat       from 'date-fns/format';
import DateIsToday      from 'date-fns/is_today';
import DateIsYesterday  from 'date-fns/is_yesterday';
import DateIsThisYear   from 'date-fns/is_this_year';
import PropTypes        from 'prop-types';
import React            from "react";

import BaobabComponent  from './BaobabComponent';

import Data             from '../js/Data';

const STATUS = {
    NEW:       'New',
    STARTED:   'Started',
    READY:     'Ready',
    CONFIRMED: 'Confirmed',
    SENT:      'Sent'
};

export default class OrderRow extends BaobabComponent {
    static propTypes = {
        id: PropTypes.string.isRequired
    };

    stateQueries() {
        const {id: sId} = this.props;
        return {
            order:    ['local', 'orders', sId],
            order_id: ['state', 'app', 'order_id'],
            item_id:  ['state', 'app', 'item_id'],
            action:   ['state', 'app', 'action']
        }
    }

    render() {
        const {
            order:    oOrder,
            order_id: sActiveOrderId
        } = this.state;

        let bActive = oOrder.id === sActiveOrderId;

        return (
            <tr className={ClassNames({active: bActive})}>
                <td>{oOrder.name}</td>
                <td title={DateFormat(oOrder.date, 'dddd, MMMM Do, YYYY HH:mm:ss')}>{this.renderDate()}</td>
                {this.renderLink()}
            </tr>
        );
    }

    renderLink() {
        const {
            order: oOrder,
            order_id: sActiveOrderId
        } = this.state;

        let bActive = oOrder.id === sActiveOrderId;

        if (bActive) {
            return <td><a className="ui green icon button" onClick={this.toggleActive}>Open <i className="arrow right icon"/></a></td>;
        }

        switch(oOrder.status) {
            case STATUS.NEW:
            case STATUS.STARTED:
                return <td><a className="ui blue button" onClick={this.toggleActive}>Open</a></td>;

            case STATUS.READY:
            case STATUS.CONFIRMED:
                return <td><a className="ui teal button" onClick={this.toggleActive}>Open</a></td>;

            case STATUS.SENT:
                return <td><a className="ui button" onClick={this.toggleActive}>Open</a></td>;
        }
    }

    renderDate() {
        const {order: oOrder} = this.state;

        switch(true) {
            case DateIsToday(oOrder.date):
                return 'Today, ' + DateFormat(oOrder.date, 'h:mma');

            case DateIsYesterday(oOrder.date):
                return 'Yesterday, ' + DateFormat(oOrder.date, 'h:mma');

            case DateIsThisYear(oOrder.date):
                return DateFormat(oOrder.date, 'dddd, MMMM Do');

            default:
                return DateFormat(oOrder.date, 'dddd, MMMM Do, YYYY');
        }
    }

    toggleActive = () => {
        const {
            order:          oOrder,
            order_id:       sActiveOrderId
        } = this.state;

        this.CURSORS.order_id.set(sActiveOrderId !== oOrder.id ? oOrder.id : null);
        this.CURSORS.item_id.set(null);
        this.CURSORS.action.set(Data.ACTIONS.FIELDS);
    }
}



