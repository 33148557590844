import ClassNames       from "classnames";
import DateFormat       from 'date-fns/format';
import PropTypes        from 'prop-types';
import React            from "react";

import BaobabComponent  from './BaobabComponent';

import API              from '../js/API';
import addWeekdays      from '../js/addWeekdays';
import Data             from '../js/Data';

export default class ShippingRow extends BaobabComponent {
    static propTypes = {
        id: PropTypes.number.isRequired
    };

    stateQueries() {
        const {id: sId} = this.props;
        return {
            shipping: {
                cursor: ['local', 'shipping', sId],
                setState: oState => {
                    oState.changed     = Data.isDiff(['shipping', sId])
                }
            }
        }
    }

    render() {
        const {
            shipping:  oShipping,
            changed:   bChanged
        } = this.state;

        return (
            <tr key={oShipping.id}>
                <td>{oShipping.name}</td>
                <td className="ui field"><input value={oShipping.days} type="number" min="1" step="1" onChange={this.update}/></td>
                <td>{DateFormat(addWeekdays(new Date(), oShipping.days), 'dddd, MMMM Do, YYYY')}</td>
                <td><a className={ClassNames("ui green icon button", {disabled: !bChanged})} onClick={this.save}><i className="save icon"/> Save</a></td>
            </tr>
        );
    }

    update = oEvent => {
        this.CURSORS.shipping.set('days', parseInt(oEvent.target.value, 10));
    };

    save = () => {
        const {shipping: oShipping} = this.state;
        API.post(`/shipping/${oShipping.id}`, oShipping, (oError, oResponse) => Data.mergeResponse(oResponse));
    };
}



