"use strict";

import parseDate from 'date-fns/parse';
import addDays   from 'date-fns/add_days';
import isWeekend from 'date-fns/is_weekend';

function addWeekdays(sStartDate, iDays) {
    const iAdd      = iDays < 0 ? -1 : 1;

    let iRemaining  = Math.abs(iDays);
    let oDate       = parseDate(sStartDate);

    while(iRemaining) {
        oDate = addDays(oDate, iAdd);
        if (!isWeekend(oDate)) {
            iRemaining--;
        }
    }

    return oDate;
}

export default addWeekdays;