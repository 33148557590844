"use strict";
import ClassNames      from 'classnames';
import DateFormat      from 'date-fns/format';
import PropTypes       from 'prop-types';
import React           from "react";

import BaobabComponent from './BaobabComponent';

import addWeekdays     from '../js/addWeekdays';

export default class ShippingField extends BaobabComponent {
    static propTypes = {
        name:           PropTypes.string,
        required:       PropTypes.bool,
        error:          PropTypes.bool,
        show_estimated: PropTypes.bool,
        shipping_id:    PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        format:         PropTypes.string,
        onChange:       PropTypes.func.isRequired
    };

    static defaultProps = {
        name:           'shipping_id',
        required:       false,
        error:          false,
        format:         'dddd, MMMM Do',
        shipping_id:    '',
        show_estimated: true
    };

    stateQueries() {
        return {
            shipping: {
                cursor: ['local', 'shipping'],
                setState: oState => {
                    oState.shipping_array = Object.values(oState.shipping);
                }
            }
        }
    }

    render() {
        const {
            name:           sName,
            shipping_id:    iShippingId,
            required:       bRequired,
            error:          bError,
            format:         sFormat,
            show_estimated: bShowEstimated,
            onChange
        } = this.props;

        const {shipping_array: aShipping} = this.state;

        return (
            <div className={ClassNames("field", {error: bError, required: bRequired})}>
                <label>Shipping Method (Dates are Est.)</label>

                <select name={sName} value={iShippingId || ''} onChange={onChange}>
                    <option value={''}>Choose a Shipping Method</option>
                    {aShipping.map(oShipment => {
                        if (bShowEstimated) {
                            return <option key={oShipment.id} value={oShipment.id}>{oShipment.name} - {DateFormat(addWeekdays(new Date(), oShipment.days), sFormat)}</option>
                        }

                        return <option key={oShipment.id} value={oShipment.id}>{oShipment.name}</option>
                    })}
                </select>
            </div>
        );
    }
}