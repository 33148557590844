"use strict";

import React, {PropTypes} from "react";
import BaobabComponent    from './BaobabComponent';

import BackRow     from './BackRow';
import Back        from './Back';

import UUID        from '../js/UUID';

export default class Backs extends BaobabComponent {
    stateQueries() {
        return {
            NO_BACK_ID: ['local', 'settings', 'no_back_id', 'value'],
            back_id:    ['state', 'app', 'back_id'],
            backs:      {
                cursor:   ['local', 'backs'],
                setState: oState => {
                    oState.backs_array = Object.values(oState.backs);
                    oState.backs_array
                          .sort((a, b) => a.name.localeCompare(b.name))
                          .sort(a      => a.id     === oState.NO_BACK_ID ? -1 : 1)
                          .sort(a      => a.status === 'Active'          ? -1 : 1);
                }
            }
        }
    }

    render() {
        const {
            backs_array: aBacks
        } = this.state;

        return (
            <div className="ui grid">
                <div className="row">
                    <div className="four wide column">
                        <table className="ui very basic table">
                            <tbody>
                                {aBacks.map(oBack => <BackRow key={oBack.id} id={oBack.id}/>)}

                                <tr>
                                    <td> </td>
                                    <td className="right aligned">
                                        <a className="ui positive icon button" onClick={this.add}> Add <i className="add icon"/></a>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    {this.renderBack()}
                </div>
            </div>
        );
    }

    renderBack() {
        const {back_id: sBackId} = this.state;

        if (!sBackId) {
            return null;
        }

        return (
            <div className="twelve wide column">
                <Back id={sBackId} />
            </div>
        )
    }

    add = () => {
        const oNewBack = {
            id:     UUID(),
            name:   'New Back-Image',
            status: 'Active'
        };

        this.CURSORS.backs.set(oNewBack.id, oNewBack);
        this.CURSORS.back_id.set(oNewBack.id);
    }
}