"use strict";

import React              from "react";

import BaobabComponent    from './BaobabComponent';
import ShippingRow        from './ShippingRow';

export default class Shipping extends BaobabComponent {
    stateQueries() {
        return {
            shipping:      {
                cursor:   ['local', 'shipping'],
                setState: oState => {
                    oState.shipping_array = Object.values(oState.shipping);
                }
            }
        }
    }

    render() {
        const {
            shipping_array: aShipping
        } = this.state;

        return (
            <table className="ui very basic collapsing table">
                <thead>
                    <tr>
                        <th>Method</th>
                        <th>Days</th>
                        <th>If Sent Today</th>
                        <th> </th>
                    </tr>
                </thead>
                <tbody className="ui form">
                    {aShipping.map(oShipping => <ShippingRow key={oShipping.id} id={oShipping.id} />)}
                </tbody>
            </table>
        );
    }
}