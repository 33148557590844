
import React            from "react";

import BaobabComponent  from './BaobabComponent';
import Order            from './Order';
import OrderRow         from './OrderRow';
import OrderItem        from './OrderItem';

export default class Orders extends BaobabComponent {
    stateQueries() {
        return {
            orders:    {
                cursor: ['local', 'orders'],
                setState: oState => {
                    const aInclude = ['New', 'Started', 'Ready', 'Confirmed', 'Sent'];
                    oState.orders_array = Object.values(oState.orders).filter(oOrder => aInclude.indexOf(oOrder.status) > -1);
                    oState.orders_array.sort((a, b) => {
                        return new Date(b.date) - new Date(a.date);
                    });
                }
            },
            item_id:     ['state', 'app', 'item_id'],
            order_id: {
                cursor:   ['state', 'app', 'order_id'],
                setState: oState => {
                    if (!oState.order_id) {
                        oState.order = null;
                        return null;
                    }

                    if (!oState.orders[oState.order_id]) {
                        oState.order = null;
                        return null;
                    }

                    oState.order = oState.orders[oState.order_id];
                }
            }
        }
    }

    render() {
        const {
            orders_array: aOrders
        } = this.state;

        return (
                <div className="ui stackable grid">
                    <div className="row">
                        <div className="five wide column">
                            <div className="ui segment">
                                <div className="ui header">Orders</div>

                                <table className="ui very basic table">
                                    <tbody>
                                        {aOrders.map(oOrder => <OrderRow key={oOrder.id} id={oOrder.id}/>)}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        {this.renderOrder()}
                        {this.renderItem()}
                    </div>
                </div>
        );
    }

    renderOrder() {
        const {order: oOrder} = this.state;

        if (!oOrder) {
            return null;
        }

        return (
            <div className="five wide column">
                <Order id={oOrder.id}/>
            </div>
        )
    }

    renderItem() {
        const {
            order:   oOrder,
            item_id: sActiveItemId
        } = this.state;

        if (!sActiveItemId) {
            return null;
        }

        if (!oOrder) {
            return null;
        }

        return (
            <div className="six wide column">
                <OrderItem id={sActiveItemId}/>
            </div>
        )
    }
}