"use strict";

import Baobab, {monkey} from 'baobab';
import isEquivalent from './isEquivalent';

export default class Data {
    static TABS = {
        ORDERS:     'Orders',
        PRODUCTS:   'Products',
        ASSETS:     'Assets'
    };

    static ACTIONS = {
        FIELDS: 'Fields',
        VENDOR: 'Vendor'
    };

    static ASSETS = {
        BACKS:        'Backs',
        INSTRUCTIONS: 'Instructions',
        SHIPPING:     'Shipping',
        SETTINGS:     'Settings'
    };

    static Base = new Baobab({
        server: {},
        local:  {
            crowdsigns: {
                settings: {},
                products: {}
            },
            shopify:    {
                orders:   {},
                products: {}
            },
            vendor:     {
                products:    {},
                orders:      {},
                order_items: {}
            }
        },
        state: {
            url: {
                path:   '/',
                search: '',
                query:  {},
                active: 'Home',
                faq:    false
            },
            app: {
                tab:            Data.TABS.ORDERS,
                order_id:       null,
                item_id:        null,
                product_id:     null,
                variant_id:     null,
                back_id:        null,
                instruction_id: null,
                asset:          Data.ASSETS.BACKS,
                action:         Data.ACTIONS.FIELDS
            },
            printed: {}
        }
    });

    /**
     *
     * @param {Array} aPath
     * @return {Boolean}
     */
    static has(aPath) {
        try {
            return Data.Base.exists(aPath);
        } catch (e) {
            return false;
        }
    }

    static isDiff(aPathToDiff) {
        const aPath = aPathToDiff.slice(); // Copy Before Shift

        if (['server', 'local'].indexOf(aPath[0]) > -1) {
            aPath.shift();
        }

        let oServer = Data.Base.select('server').select(aPath).get();
        let oLocal  = Data.Base.select('local').select(aPath).get();

        return Data.objectDiff(oServer, oLocal);
    }

    static objectDiff(oFirst, oSecond) {
        return !isEquivalent(oFirst, oSecond);
    }

    static watch = oEvent => {
        console.log('Data.Watch', oEvent);
    };

    static listen(...aTables) {
        let oListeners = {};
        aTables.map(sTable => {
            let aSplit = sTable.split('.');
            oListeners[aSplit[aSplit.length -1]] = aSplit;
        });
        return Data.Base.watch(oListeners);
    }

    static toggle(aPath) {
        let oCursor = Data.Base.select(aPath);
        oCursor.set(!oCursor.get())
    }

    static replaceResponse(oResponse) {
        for (let sKey in oResponse) {
            if (sKey.charAt(0) === '_') {
                delete(oResponse[sKey]);
            } else {
                Data.Base.set(['server', sKey], oResponse[sKey]);
                Data.Base.set(['local', sKey], oResponse[sKey]);
            }
        }
    }

    static mergeResponse(oResponse) {
        if (!oResponse) {
            return;
        }
        
        for (let sKey in oResponse) {
            if (sKey.charAt(0) === '_') {
                delete(oResponse[sKey]);
            }
        }

        Data.Base.deepMerge({
            server: oResponse,
            local:  oResponse
        });
    }

    static sortedArray(sTable, oData) {
        let aOutput = [];
        let aSorts  = Data.Base.get(['local', 'sorts', sTable]);
        if (aSorts) {
            aSorts.forEach(iIndex => {
                if (oData[iIndex]) {
                    aOutput.push(oData[iIndex])
                }
            });
        }

        return aOutput;
    }

    static sortObjectBy(oData, sField, bAscending = true) {
        if (!oData) {
            return [];
        }

        let aOutput = Object.values(oData);

        aOutput.sort((a, b) => {
            if (a[sField] < b[sField]) {
                return bAscending ? -1 :  1;
            } else if (a[sField] > b[sField]) {
                return bAscending ?  1 : -1;
            }

            return 0;
        });

        return aOutput;
    }

    static sortedBy(sTable, sField) {
        return Data.sortObjectBy(Data.Base.get(['local', sTable]), sField);
    }

    static objectFilter(oObject, fFilter = () => true) {
        let oData = {};

        if (oObject) {
            Object.keys(oObject).forEach(sId => {
                if (fFilter(oObject[sId])) {
                    oData[sId] = oObject[sId];
                }
            });
        }

        return oData;
    }

    static filter(aPath, fFilter) {
        return Data.objectFilter(Data.Base.get(aPath), fFilter);
    }

    static filterCount(aPath, fFilter) {
        let iCount    = 0;
        let oPathData = Data.Base.get(aPath);
        if (oPathData) {
            Object.values(oPathData).forEach(oItem => {
                if (fFilter(oItem)) {
                    iCount++;
                }
            });
        }

        return iCount;
    }

    static filterRandom(aPath, fFilter) {
        return this.objectFilterRandom(this.filter(aPath, fFilter));
    }

    static objectFilterRandom(oData) {
        let aKeys = Object.keys(oData);
        let sKey  = aKeys[Math.floor(Math.random() * aKeys.length)];
        return oData[sKey];
    }

    static filterFirst(aPath, fFilter) {
        let oPathData = Data.Base.get(aPath);
        if (oPathData) {
            return Data.objectFilterFirst(oPathData, fFilter);
        }
    }

    static objectFilterFirst(oObject, fFilter = () => true) {
        let aPathData = Object.values(oObject);
        for (let oItem of aPathData) {
            if (fFilter(oItem)) {
                return oItem;
            }
        }
    }

    /**
     *
     * @param {Array} aNoteAttributes
     * @return {Object}
     */
    static convertFromNodeAttributes(aNoteAttributes) {
        let oAttributes = {};
        aNoteAttributes.map(oNoteAttribute => {
            oAttributes[oNoteAttribute.name] = oNoteAttribute.value;
        });

        return oAttributes;
    };

    /**
     *
     * @param {Object} oAttributes
     * @return {Array}
     */
    static convertToNodeAttributes(oAttributes) {
        let aNoteAttributes = [];
        Object.keys(oAttributes).map(sKey => {
            aNoteAttributes.push({
                name:  sKey,
                value: oAttributes[sKey]
            });
        });

        return aNoteAttributes;
    }
}