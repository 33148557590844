"use strict";

import ClassNames       from 'classnames';
import DateFormat       from 'date-fns/format';
import DateIsThisYear   from 'date-fns/is_this_year';
import DateIsToday      from 'date-fns/is_today';
import DateIsYesterday  from 'date-fns/is_yesterday';
import DateSince        from 'date-fns/distance_in_words_to_now';
import DateSubDays      from 'date-fns/sub_days';
import React            from 'react';

import BaobabComponent  from './BaobabComponent';

import API              from '../js/API';
import Data             from '../js/Data';
import isEmpty          from '../js/isEmpty';

import Orders           from './Orders';
import Products         from './Products';
import Assets           from './Assets';

export default class App extends BaobabComponent {
    stateQueries() {
        return {
            loading:   {
                cursor: BaobabComponent.LOCAL_STATE,
                default: false
            },
            date: {
                cursor:  BaobabComponent.LOCAL_STATE,
                default: null
            },
            date_formatted: {
                cursor:  BaobabComponent.LOCAL_STATE,
                default: null
            },
            authed: {
                cursor:  BaobabComponent.LOCAL_STATE,
                default: false
            },
            auth_error: {
                cursor:  BaobabComponent.LOCAL_STATE,
                default: null
            },
            email: {
                cursor:  BaobabComponent.LOCAL_STATE,
                default: ''
            },
            password: {
                cursor:  BaobabComponent.LOCAL_STATE,
                default: ''
            },
            tab:       [ 'state', 'app', 'tab' ],
            products:  [ 'local', 'products' ],
            orders:    [ 'local', 'orders' ]
        }
    }

    componentWillMount() {
        API.registerForbiddenCallback(this.forbidden);
    }

    fireItUp = () => {
        this.getData();
        setInterval(this.updateDate, 1000);
    };

    forbidden = () => {
        this.CURSORS.authed.set(false);
    };

    updateDate = () => {
        const {date: sDate} = this.state;

        if (!sDate) {
            return;
        }

        this.CURSORS.date_formatted.set(App.getDate(sDate));
    };

    static getDate(sDate) {
        switch (true) {
            case DateIsToday(sDate):
                return DateSince(sDate, {includeSeconds: true, addSuffix: true});

            case DateIsYesterday(sDate):
                return 'Yesterday, ' + DateFormat(sDate, 'h:mma');

            case DateIsThisYear(sDate):
                return DateFormat(sDate, 'dddd, MMMM Do');

            default:
                return DateFormat(sDate, 'dddd, MMMM Do, YYYY');
        }
    }

    render() {
        const {
            authed:         bAuthed,
            loading:        bLoading,
            date_formatted: sDate,
            date:           sDateOriginal,
            tab:            sTab
        } = this.state;

        if (!bAuthed) {
            return this.renderAuth();
        }

        return (
            <div className="ui container">
                <div className="ui stackable menu">
                    <img className="item" src="/img/crowdsigns_menu4.png" />
                    <a className={ClassNames("item", {active: sTab === Data.TABS.ORDERS  })} onClick={this.showOrders}>Orders</a>
                    <a className={ClassNames("item", {active: sTab === Data.TABS.PRODUCTS})} onClick={this.showProducts}>Products</a>
                    <a className={ClassNames("item", {active: sTab === Data.TABS.ASSETS})} onClick={this.showAssets}>Assets</a>

                    <a className="right item" onClick={this.getData} title={DateFormat(sDateOriginal, 'dddd, MMMM Do, YYYY hh:mm:ss a')}>
                        <i className={ClassNames("refresh icon", {loading: bLoading})}/>
                        {sDate ? 'Updated: ' + sDate : '...'}
                    </a>
                </div>
                {this.renderPage()}
            </div>
        );
    }

    renderPage() {
        const {
            tab:      sTab,
            products: oProducts,
            orders:   oOrders
        } = this.state;

        if (isEmpty(oProducts)) {
            return (
                <div className="ui icon message">
                    <i className="notched circle loading icon" />
                    <div className="content">
                        <div className="header">
                            One Moment Please
                        </div>
                        <p>Loading Orders</p>
                    </div>
                </div>
            );
        }

        if (isEmpty(oOrders)) {
            return (
                <div className="ui icon message">
                    <i className="cocktail icon"/>
                    <div className="content">
                        <div className="header">
                            Call it a Day!
                        </div>
                        <p>No Currently Unfulfilled Orders</p>
                    </div>
                </div>
            );
        }

        switch(sTab) {
            case Data.TABS.ORDERS:   return <Orders />;
            case Data.TABS.PRODUCTS: return <Products />;
            case Data.TABS.ASSETS:   return <Assets />;
        }

        return <div>Where am I?</div>;
    }

    renderAuth() {
        const {
            email:      sEmail,
            password:   sPassword,
            auth_error: sAuthError
        } = this.state;

        return (
            <div className="ui container">
                <div className="ui middle aligned center aligned grid">
                    <div className="row" />
                    <div className="row">
                        <div className="four wide column" />
                        <div className="eight wide column">

                            {!!sAuthError && <div className="ui error message">{sAuthError}</div>}

                            <form className="ui large form" onSubmit={this.tryAuth}>
                                <div className="ui stacked segment">
                                    <div className={ClassNames("field", {error: !!sAuthError})}>
                                        <div className="ui left icon input">
                                            <i className="user icon" />
                                            <input type="email" name="email" placeholder="E-mail Address" value={sEmail} onChange={this.setEmail} />
                                        </div>
                                    </div>
                                    <div className={ClassNames("field", {error: !!sAuthError})}>
                                        <div className="ui left icon input">
                                            <i className="lock icon"/>
                                            <input type="password" name="password" placeholder="Password" value={sPassword} onChange={this.setPassword} />
                                        </div>
                                    </div>

                                    <button className="ui fluid large submit button" onClick={this.tryAuth}>Log In <i className="sign in icon"/></button>
                                </div>
                            </form>
                        </div>
                        <div className="four wide column"/>
                    </div>
                </div>
            </div>
        )
    }

    setEmail = oEvent => {
        this.CURSORS.email.set(oEvent.target.value);
        this.CURSORS.auth_error.set(null);
    };

    setPassword = oEvent => {
        this.CURSORS.password.set(oEvent.target.value);
        this.CURSORS.auth_error.set(null);
    };

    tryAuth = oEvent => {
        const {
            email:    sEmail,
            password: sPassword
        } = this.state;

        oEvent.preventDefault();

        API.token(sEmail, sPassword, (oError, oResponse) => {
            if (oError) {
                console.error(oError);

                if (oResponse && oResponse.error) {
                    this.CURSORS.auth_error.set(oResponse.error);
                } else {
                    this.CURSORS.auth_error.set('Could not Authenticate');
                }
            } else {
                this.fireItUp();
                this.CURSORS.email.set('');
                this.CURSORS.password.set('');
                this.CURSORS.authed.set(true);
            }
        })
    };

    showOrders = () => {
        this.CURSORS.tab.set(Data.TABS.ORDERS);
    };

    showProducts = () => {
        this.CURSORS.tab.set(Data.TABS.PRODUCTS);
    };

    showAssets = () => {
        this.CURSORS.tab.set(Data.TABS.ASSETS);
    };

    getData = () => {
        this.CURSORS.loading.set(true);

        let oTwoWeeksAgo = DateSubDays(new Date(), 14);
        let sTwoWeeksAgo = DateFormat(oTwoWeeksAgo, 'YYYY-MM-DD');

        API.query([
            "/settings",
            "/backs",
            "/instructions",
            "/vendor_products",
            "/products",
            "/variants",
            "/shipping",
            `/orders?search=date_added>${sTwoWeeksAgo}`,
            "/orders/{orders.id}/items"
        ], (oError, oResponse) => {
            this.CURSORS.loading.set(false);
            if(oError) {
                console.error(oError);
            } else {
                this.CURSORS.date.set(oResponse._server.date_w3c);
                Data.mergeResponse(oResponse);
            }
        });
    }
}
