import PropTypes        from 'prop-types';
import React            from "react";

import BaobabComponent  from './BaobabComponent';

export default class ProductVariantRow extends BaobabComponent {
    static propTypes = {
        id: PropTypes.string.isRequired
    };
    
    stateQueries() {
        const {id: sId} = this.props;
        return {
            backs:               ['local', 'backs'],
            instructions:        ['local', 'instructions'],
            vendor_products:     ['local', 'vendor_products'],
            shipping:            ['local', 'shipping'],
            variant:             {
                cursor: ['local', 'variants', sId],
                setState: oState => {
                    oState.instruction     = oState.variant.instruction_id    ? oState.instructions[oState.variant.instruction_id]       : null;
                    oState.back            = oState.variant.back_id           ? oState.backs[oState.variant.back_id]                     : null;
                    oState.shipping_method = oState.variant.shipping_id       ? oState.shipping[oState.variant.shipping_id]              : null;
                    oState.vendor_product  = oState.variant.vendor_product_id ? oState.vendor_products[oState.variant.vendor_product_id] : null;
                }
            },
            variant_id:          {
                cursor:   ['state', 'app', 'variant_id'],
                setState: oState => {
                    oState.is_active  = oState.variant_id === sId;
                    oState.has_active = !!oState.variant_id;
                }
            }
        }
    }

    render() {
        const {
            has_active: bHasActive,
            is_active:  bIsActive
        } = this.state;

        if (bHasActive) {
            if (bIsActive) {
                return this.renderActive();
            } else {
                return this.renderHasActive();
            }
        } else {
            return this.renderNoneActive();
        }
    }

    renderActive() {
        const {variant: oVariant} = this.state;

        return (
            <tr className="active">
                <td>{oVariant.title}</td>
                <td className="right aligned"><a className="ui icon button" onClick={this.setVariant}> Edit <i className="close icon"/></a></td>
            </tr>
        )
    }

    renderHasActive() {
        const {variant: oVariant} = this.state;

        return (
            <tr>
                <td>{oVariant.title}</td>
                <td className="right aligned"><a className="ui icon button" onClick={this.setVariant}> Edit <i className="arrow right icon"/></a></td>
            </tr>
        )
    }

    renderNoneActive() {
        const {variant: oVariant} = this.state;

        return (
            <tr>
                <td>{oVariant.title}</td>
                {this.renderInstructions()}
                {this.renderShipping()}
                {this.renderBack()}
                {this.renderProduct()}
                <td className="right aligned"><a className="ui icon button" onClick={this.setVariant}> Edit <i className="arrow right icon"/></a></td>
            </tr>
        )
    }

    renderInstructions() {
        const { instruction: oInstruction } = this.state;

        if (!oInstruction) {
            return <td className="error">None</td>
        }

        return <td>{oInstruction.name}</td>
    }

    renderShipping() {
        const { shipping_method: oShippingMethod } = this.state;

        if (!oShippingMethod) {
            return <td className="error">None</td>
        }

        return <td>{oShippingMethod.name}</td>
    }

    renderBack() {
        const { back: oBack  } = this.state;

        if (!oBack) {
            return <td className="error">None</td>
        }

        return <td>{oBack.name}</td>
    }

    renderProduct() {
        const { vendor_product: oVendorProduct } = this.state;

        if (!oVendorProduct) {
            return <td className="error">None</td>
        }

        return <td>{oVendorProduct.name}</td>
    }

    setVariant = () => {
        const {variant: oVariant} = this.state;

        this.CURSORS.variant_id.set(this.state.variant_id !== oVariant.id ? oVariant.id : null);
    };
}