"use strict";

import DateFormat       from 'date-fns/format';
import DateIsThisYear   from 'date-fns/is_this_year';
import DateIsToday      from 'date-fns/is_today';
import DateIsYesterday  from 'date-fns/is_yesterday';
import DateSince        from 'date-fns/distance_in_words_to_now';
import React            from "react";

import BaobabComponent  from './BaobabComponent';

export default class Settings extends BaobabComponent {
    stateQueries() {
        return {
            settings:      {
                cursor:   ['local', 'settings'],
                setState: oState => {
                    oState.settings_array = Object.values(oState.settings);
                }
            },
            formatted: {
                cursor:  BaobabComponent.LOCAL_STATE,
                default: {}
            }
        }
    }

    componentWillMount() {
        super.componentWillMount();
        this.bMounted = true;
        this.updateDates();
    }

    componentWillUnmount() {
        super.componentWillUnmount();
        this.bMounted = true;
    }

    updateDates = () => {
        const {settings_array: aSettings} = this.state;

        let oFormatted = {};
        aSettings.map(oSetting => {
            if (oSetting.name.indexOf('date') > -1) {
                oFormatted[oSetting.name] = Settings.getDate(oSetting.value);
            }
        });

        this.CURSORS.formatted.set(oFormatted);

        if (this.bMounted) {
            setTimeout(this.updateDates, 1000);
        }
    };

    render() {
        const {
            settings_array: aSettings,
            formatted:      oFormatted
        } = this.state;

        return (
            <table className="ui very basic collapsing table">
                <thead>
                    <tr>
                        <th>Setting</th>
                        <th>Value</th>
                        <th>Description</th>
                    </tr>
                </thead>
                <tbody>
                    {aSettings.map(oSetting => {
                        return (
                            <tr>
                                <td>{oSetting.name}</td>
                                <td>{oFormatted[oSetting.name] ? oFormatted[oSetting.name] : oSetting.value}</td>
                                <td><p>{oSetting.description}</p></td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        );
    }

    static getDate(sDate) {
        switch (true) {
            case DateIsToday(sDate):
                return DateSince(sDate, {includeSeconds: true, addSuffix: true});

            case DateIsYesterday(sDate):
                return 'Yesterday, ' + DateFormat(sDate, 'h:mma');

            case DateIsThisYear(sDate):
                return DateFormat(sDate, 'dddd, MMMM Do');

            default:
                return DateFormat(sDate, 'dddd, MMMM Do, YYYY');
        }
    }
}