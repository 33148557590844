import ClassNames       from 'classnames';
import DateFormat       from 'date-fns/format';
import PropTypes        from 'prop-types';
import React            from "react";

import BaobabComponent  from './BaobabComponent';
import OrderItemRow     from './OrderItemRow';
import ShippingField    from './ShippingField';

import API              from '../js/API';
import Data             from '../js/Data';

const oMoneyFormatter = new Intl.NumberFormat('en-US', {
    style:    'currency',
    currency: 'USD'
});

const ORDER_STATUS = {
    NEW:       'New',
    STARTED:   'Started',
    READY:     'Ready',
    CONFIRMED: 'Confirmed',
    SENT:      'Sent'
};

const ITEM_STATUS = {
    NEW:      'New',
    PREPARED: 'Prepared',
    READY:    'Ready',
};

export default class Order extends BaobabComponent {
    static propTypes = {
        id: PropTypes.string.isRequired
    };

    stateQueries() {
        const {id: sId} = this.props;

        return {
            shipping:       ['local', 'shipping'],
            order: {
                cursor: ['local', 'orders', sId],
                setState: oState => {
                    oState.order_items  = Object.values(oState.items).filter(oItem => oItem.order_id === oState.order.id);
                    this.prepReadyState(oState);

                    oState.shopify_data = JSON.parse(oState.order.shopify_data);
                    oState.vendor_order = oState.order.signs2trade_data ? JSON.parse(oState.order.signs2trade_data) : null;
                }
            },
            items: {
                cursor: ['local', 'items'],
                setState: oState => {
                    oState.order_items = Object.values(oState.items).filter(oItem => oItem.order_id === oState.order.id);
                    this.prepReadyState(oState);
                }
            },
            loading: {
                cursor:  BaobabComponent.LOCAL_STATE,
                default: false
            }
        }
    }

    prepReadyState(oState) {
        oState.ready = oState.order_items.filter(oItem => oItem.status === ITEM_STATUS.READY).length === oState.order_items.length;
    }

    componentWillReceiveProps(oNextProps) {
        if (oNextProps.id !== this.props.id) {
            this.overrideCursor('order', {cursor: ['local', 'orders', oNextProps.id]});
        }
    }

    confirmOrder = oEvent => {
        const { order: oOrder} = this.state;

        this.CURSORS.loading.set(true);

        API.post(`/orders/${oOrder.id}`, {
            status:         ORDER_STATUS.CONFIRMED
        }, (oError, oResponse) => {
            this.CURSORS.loading.set(false);

            if (oError) {
                console.error(oError);
            } else {
                Data.mergeResponse(oResponse);
            }
        });
    };

    render() {
        const {
            order:             oOrder
        } = this.state;

        return (
            <div className="ui segment">
                <div className="ui header">Order {oOrder.name} </div>

                <div>
                    <p>{DateFormat(oOrder.date, 'dddd, MMMM Do, YYYY HH:mm:ss')}</p>
                    <p>{oOrder.status}</p>

                    <div className="ui vertical segment">
                        <a className="ui icon button" href={'https://getmoney1.myshopify.com/admin/orders/' + oOrder.id} target="_blank"> <i className="external icon"/> Open on Shopify</a>
                    </div>

                    {this.renderTotals()}
                    {this.renderNote()}
                    {this.renderItems()}
                    {this.renderOrder()}
                </div>
            </div>
        );
    }

    renderTotals() {
        const {
            order: oOrder
        } = this.state;

        return (
            <div className="ui vertical segment">
                <div className="ui medium header">Total</div>
                <table className="ui very basic table">
                    <tbody>
                        <tr>
                            <td>Shopify</td>
                            <td>{oMoneyFormatter.format(oOrder.shopify_total)}</td>
                        </tr>
                        {
                            oOrder.signs2trade_total &&
                            (
                                <tr>
                                    <td>Signs2Trade</td>
                                    <td>{oMoneyFormatter.format(oOrder.signs2trade_total)}</td>
                                </tr>
                            )
                        }
                        {
                            oOrder.signs2trade_shipping &&
                            (
                                <tr>
                                    <td>Shipping</td>
                                    <td>{oMoneyFormatter.format(oOrder.signs2trade_shipping)}</td>
                                </tr>
                            )
                        }
                        {
                            oOrder.date_ship &&
                            (
                                <tr>
                                    <td>Ship Date</td>
                                    <td>{DateFormat(oOrder.date_ship, 'dddd, MMMM Do')}</td>
                                </tr>
                            )
                        }
                    </tbody>
                </table>
            </div>
        )
    }

    renderNote() {
        const {
            order: oOrder
        } = this.state;

        if (!oOrder.note) {
            return null;
        }

        return (
            <div className="ui vertical segment">
                <div className="ui medium header">Note</div>
                <p>{oOrder.note}</p>
            </div>
        )
    }

    renderItems() {
        const {
            vendor_order:       oVendorOrder,
            order_items:        aLineItems
        } = this.state;

        if (oVendorOrder) {
            return null;
        }

        return (
            <div className="ui vertical segment">
                <div className="ui medium header">{aLineItems.length} {aLineItems.length === 1 ? 'Item' : 'Items'}</div>
                <div className="ui cards">
                    {aLineItems.map(oLineItem => <OrderItemRow key={oLineItem.id} id={oLineItem.id}/>)}
                </div>
            </div>
        )
    }

    renderOrder() {
        const {
            order:             oOrder,
            vendor_order:      oVendorOrder,
            loading:           bLoading
        } = this.state;


        switch(oOrder.status) {
            case ORDER_STATUS.NEW:
                return null;

            case ORDER_STATUS.STARTED:
                return (
                    <div className={ClassNames("ui vertical segment", {loading: bLoading})}>
                        <div className="ui medium header">Vendor Order</div>
                        <a className="ui disabled button"><i className="shipping icon"/> Send Order to Vendor</a>
                    </div>
                );

            case ORDER_STATUS.READY:
                return (
                    <div className={ClassNames("ui vertical segment", {loading: bLoading})}>
                        <div className="ui medium header">Vendor Order</div>
                        {this.renderFinalizeOrder()}

                        <a className="ui green button" onClick={this.confirmOrder}><i className="shipping icon"/>Send Order to Vendor</a>
                    </div>
                );

            case ORDER_STATUS.CONFIRMED:
                return (
                    <div className={ClassNames("ui vertical segment", {loading: bLoading})}>
                        <div className="ui medium header">Vendor Order</div>
                        {this.renderFinalizeOrder()}

                        <a className="ui green disabled button"><i className="shipping icon"/>Order has been Sent to Vendor</a>
                    </div>
                );

            case ORDER_STATUS.SENT:
                if (oVendorOrder) {
                    return (
                        <div className={ClassNames("ui vertical segment", {loading: bLoading})}>
                            <div className="ui medium header">Vendor Order</div>
                            {this.renderFinishedOrder()}
                        </div>
                    );
                }
        }
    }

    renderFinishedOrder() {
        const { vendor_order: oVendorOrder } = this.state;
        return (
            <div className="ui card">
                <div className="content">
                    <div className="header">{oVendorOrder.id}</div>
                    <div className="meta">
                        <a>Status: {oVendorOrder.status}</a>
                        <a className="right floated">Total: ${oVendorOrder.total}</a>
                    </div>
                </div>
                <div className="extra content">
                    <a>{DateFormat(oVendorOrder.date, 'dddd, MMMM Do, YYYY HH:mm:ss')}</a>
                </div>
            </div>
        );
    }


    /**
     *
     * @return {XML}
     */
    renderFinalizeOrder() {
        const {
            order:        oOrder,
            shopify_data: oData
        } = this.state;

        return (
            <div className="ui form">
                <ShippingField shipping_id={oOrder.shipping_id} required={true} error={!oOrder.shipping_id} onChange={this.setShipping} />

                <table className="ui very basic table">
                    <tbody>
                        <tr>
                            <td>Company</td>
                            <td>{oData.shipping_address.company || [oData.shipping_address.first_name, oData.shipping_address.last_name].join(' ')}</td>
                        </tr>
                        <tr>
                            <td>First Name</td>
                            <td>{oData.shipping_address.first_name}</td>
                        </tr>
                        <tr>
                            <td>Last Name</td>
                            <td>{oData.shipping_address.last_name}</td>
                        </tr>
                        <tr>
                            <td>Address</td>
                            <td>{oData.shipping_address.address1}</td>
                        </tr>
                        <tr>
                            <td>Address2</td>
                            <td>{oData.shipping_address.address2}</td>
                        </tr>
                        <tr>
                            <td>City</td>
                            <td>{oData.shipping_address.city}</td>
                        </tr>
                        <tr>
                            <td>State</td>
                            <td>{oData.shipping_address.province_code}</td>
                        </tr>
                        <tr>
                            <td>Postal Code</td>
                            <td>{oData.shipping_address.zip}</td>
                        </tr>
                        <tr>
                            <td>Phone</td>
                            <td>{oData.shipping_address.phone || '-'}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        )
    }

    setShipping = oEvent => {
        const {id: sId} = this.props;

        this.CURSORS.order.set('shipping_id', oEvent.target.value);

        API.post(`/orders/${sId}`, {
            shipping_id: oEvent.target.value,
        }, (oError, oResponse) => oResponse ? Data.mergeResponse(oResponse) : null);
    }
}