import ClassNames       from 'classnames';
import PropTypes        from 'prop-types';
import React            from "react";

import BaobabComponent  from './BaobabComponent';

import API              from '../js/API';
import Data             from '../js/Data';

export default class Back extends BaobabComponent {
    static propTypes = {
        id: PropTypes.string.isRequired
    };

    stateQueries() {
        const {id: sId} = this.props;

        return {
            loading: {
                cursor:  BaobabComponent.LOCAL_STATE,
                default: false
            },
            NO_BACK_ID: ['local', 'settings', 'no_back_id', 'value'],
            back: {
                cursor:   ['local', 'backs', sId],
                setState: oState => {
                    oState.changed      = Data.isDiff(['local', 'backs', oState.back.id]);
                    oState.is_none      = oState.back.id === oState.NO_BACK_ID;
                    oState.is_archived  = oState.back.status === 'Archived';
                }
            }
        }
    }

    componentWillReceiveProps(oNextProps) {
        if (oNextProps.id !== this.props.id) {
            const {id: sId} = oNextProps;

            this.overrideCursor('back', {
                cursor:   ['local', 'backs', sId]
            });
        }
    }

    render() {
        const {
            back:    oBack
        } = this.state;

        if (!oBack) {
            return null;
        }

        return (
            <div className="ui form">
                {this.renderName()}
                {this.renderUrl()}
                {this.renderLinks()}
                {this.renderArchive()}
            </div>
        );
    }

    renderName() {
        const {
            is_none:     bIsNone,
            is_archived: bArchived,
            back:        oBack
        } = this.state;

        return (
            <div className="field">
                <label>Name</label>
                <input name="name" value={oBack.name} onChange={this.setProperty} readOnly={bIsNone || bArchived}/>
            </div>
        )
    }

    renderUrl() {
        const {
            is_none:     bIsNone,
            is_archived: bArchived,
            back:        oBack
        } = this.state;

        if (bIsNone) {
            return null
        }

        return (
            <div className="field">
                <label>Url</label>
                <textarea name="url" value={oBack.url || ''} rows="3" placeholder="Paste the Google Drive Share URL here for this sign-backing" onChange={this.setProperty} readOnly={bArchived}/>
            </div>
        )
    }

    renderLinks() {
        const {
            is_none:     bIsNone,
            is_archived: bArchived,
            changed:     bChanged
        } = this.state;

        if (bIsNone || bArchived) {
            return null;
        }

        return (
            <div className="field">
                <div className="ui two buttons">
                    <div className={ClassNames("ui positive icon button", {disabled: !bChanged})} onClick={this.save}><i className="save icon"/> Save</div>
                    <div className={ClassNames("ui icon button", {disabled: !bChanged})} onClick={this.reset}><i className="erase icon"/> Reset</div>
                </div>
            </div>
        )
    }

    renderArchive() {
        const {
            is_none:     bIsNone,
            is_archived: bArchived,
        } = this.state;

        if (bIsNone) {
            return null;
        }

        if (bArchived) {
            return (
                <div className="field">
                    <div className="ui right floated basic negative icon button" onClick={this.reactivate}><i className="archive outline icon"/> Reactivate</div>
                </div>
            )
        }

        return (
            <div className="field">
                <div className="ui right floated negative icon button" onClick={this.archive}><i className="archive icon"/> Archive</div>
            </div>
        )
    }

    setProperty = oEvent => {
        this.CURSORS.back.set(oEvent.target.name, oEvent.target.value);
    };

    save = () => {
        const {back: oBack} = this.state;
        API.post(`/backs/${oBack.id}`, oBack, (oError, oResponse) => Data.mergeResponse(oResponse));
    };

    reset = () => {
        const {back: oBack} = this.state;
        this.CURSORS.back.merge(Data.Base.get(['server', 'backs', oBack.id]));
    };

    archive = () => {
        this.CURSORS.back.merge({status: 'Archived'});
        Data.Base.commit();
        const oBack = Data.Base.get(['local', 'backs', this.props.id]);
        API.post(`/backs/${oBack.id}`, oBack, (oError, oResponse) => Data.mergeResponse(oResponse));
    };

    reactivate = () => {
        this.CURSORS.back.merge({status: 'Active'});
        Data.Base.commit();
        const oBack = Data.Base.get(['local', 'backs', this.props.id]);
        API.post(`/backs/${oBack.id}`, oBack, (oError, oResponse) => Data.mergeResponse(oResponse));
    };
}