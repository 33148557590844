"use strict";

import React, {PropTypes} from "react";
import BaobabComponent    from './BaobabComponent';

import InstructionRow     from './InstructionRow';
import Instruction        from './Instruction';

import UUID        from '../js/UUID';

export default class Instructions extends BaobabComponent {
    stateQueries() {
        return {
            instruction_id: ['state', 'app', 'instruction_id'],
            instructions: {
                cursor:   ['local', 'instructions'],
                setState: oState => {
                    oState.instructions_array = Object.values(oState.instructions);
                    oState.instructions_array
                          .sort((a, b) => a.name.localeCompare(b.name))
                          .sort(a      => a.status === 'Active' ? -1 : 1);
                }
            }
        }
    }

    render() {
        const {
            instructions_array: aInstructions
        } = this.state;

        return (
            <div className="ui grid">
                <div className="row">
                    <div className="four wide column">
                        <table className="ui very basic table">
                            <tbody>
                                {aInstructions.map(oInstruction => <InstructionRow key={oInstruction.id} id={oInstruction.id}/>)}

                                <tr>
                                    <td> </td>
                                    <td className="right aligned">
                                        <a className="ui positive icon button" onClick={this.add}> Add <i className="add icon"/></a>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    {this.renderInstruction()}
                </div>
            </div>
        );
    }

    renderInstruction() {
        const {instruction_id: sInstructionId} = this.state;

        if (!sInstructionId) {
            return null;
        }

        return (
            <div className="twelve wide column">
                <Instruction id={sInstructionId}/>
            </div>
        )
    }

    add = () => {
        const oNewInstruction = {
            id:     UUID(),
            name:   'New Instruction',
            status: 'Active'
        };

        this.CURSORS.instructions.set(oNewInstruction.id, oNewInstruction);
        this.CURSORS.instruction_id.set(oNewInstruction.id);
    }
}