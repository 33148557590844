
import ClassNames       from "classnames";
import React            from "react";

import BaobabComponent  from './BaobabComponent';
import ProductRow       from './ProductRow';
import ProductVariants  from './ProductVariants';
import ProductVariant   from './ProductVariant';

import Data             from '../js/Data';

export default class Assets extends BaobabComponent {
    stateQueries() {
        return {
            assets:     ['local', 'crowdsigns', 'assets'],
            products:       {
                cursor: ['local', 'products'],
                setState: oState => {
                    oState.product_array = Data.sortObjectBy(oState.products, 'title');
                }
            },
            product_id: ['state', 'app', 'product_id'],
            variant_id: ['state', 'app', 'variant_id']
        }
    }

    render() {
        const {
            product_array: aProducts,
            variant_id:    sActiveVariantId
        } = this.state;

        return (
            <div className="ui grid">
                <div className="row">
                    <div className="five wide column">
                        <div className="ui segment">
                            <div className="ui header">Products</div>

                            <table className="ui very basic table">
                                <tbody>
                                    {aProducts.map(oProduct => <ProductRow key={oProduct.id} id={oProduct.id} />)}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className={ClassNames({five: !!sActiveVariantId, eleven: !sActiveVariantId}, "wide column")}>
                        <ProductVariants />
                    </div>
                    {this.renderVariant()}
                </div>
            </div>
        );
    }

    renderVariant() {
        const {variant_id: sActiveVariantId} = this.state;

        if (!sActiveVariantId) {
            return null;
        }

        return (
            <div className="six wide column">
                <ProductVariant id={sActiveVariantId}/>
            </div>
        );
    }
}