import ClassNames       from 'classnames';
import PropTypes        from 'prop-types';
import React            from "react";

import BaobabComponent  from './BaobabComponent';

import Data             from '../js/Data';

export default class ProductRow extends BaobabComponent {
    static propTypes = {
        id: PropTypes.string.isRequired
    };

    stateQueries() {
        const {id: sId} = this.props;
        return {
            variant_id: ['state', 'app', 'variant_id'],
            product:    ['local', 'products', sId],
            product_id: {
                cursor:   ['state', 'app', 'product_id'],
                setState: oState => {
                    oState.is_active = oState.product_id === sId;
                }
            }
        }
    }

    render() {
        const { is_active: bActive } = this.state;

        return (
            <tr className={ClassNames({active: bActive})}>
                {this.renderTitle()}
                <td className="right aligned">{this.renderLink()}</td>
            </tr>
        );
    }

    renderTitle() {
        const {
            product:   oProduct,
            is_active: bActive
        } = this.state;

        if (oProduct.vendor.toLowerCase().trim() !== 'crowdsigns') {
            return (
                <td className="ui small header">
                    {oProduct.title}
                    <div className="sub header">{oProduct.vendor}</div>
                </td>
            );
        }

        return (
            <td className="ui small header">
                {oProduct.title}
            </td>
        )
    }

    renderLink = () => {
        const {
            is_active:    bActive
        } = this.state;

        return bActive
            ? <a className={ClassNames("ui icon button")} onClick={this.toggleActive}><i className="arrow left icon"/> Edit</a>
            : <a className={ClassNames("ui icon button")} onClick={this.toggleActive}>Edit <i className="arrow right icon"/></a>;
    };

    toggleActive = () => {
        const {
            product:    oProduct,
            product_id: sActiveProductId
        } = this.state;

        this.CURSORS.product_id.set(sActiveProductId !== oProduct.id ? oProduct.id : null);
        this.CURSORS.variant_id.set(null);
    }
}



