import ClassNames       from 'classnames';
import PropTypes        from 'prop-types';
import React            from "react";

import BaobabComponent  from './BaobabComponent';
import ShippingField    from './ShippingField';

import API              from '../js/API';
import Data             from '../js/Data';

const PRODUCT_CATEGORY = {
    CORO:           '4',
    PVC:            '119',
    SMOOTH_MATTE:   '153'
};

export default class ProductVariant extends BaobabComponent {
    static propTypes = {
        id: PropTypes.string.isRequired
    };

    stateQueries() {
        const { id: sId } = this.props;

        return {
            instructions:      ['local', 'instructions'],
            backs:             ['local', 'backs'],
            shipping:          ['local', 'shipping'],
            products:          ['local', 'products'],
            variants:          ['local', 'variants'],
            vendor_products: {
                cursor:   ['local', 'vendor_products'],
                setState: oState => {
                    oState.categories = {
                        [PRODUCT_CATEGORY.CORO]:            Data.objectFilter(oState.vendor_products, oVendorProduct => oVendorProduct.category_id === PRODUCT_CATEGORY.CORO),
                        [PRODUCT_CATEGORY.PVC]:             Data.objectFilter(oState.vendor_products, oVendorProduct => oVendorProduct.category_id === PRODUCT_CATEGORY.PVC),
                        [PRODUCT_CATEGORY.SMOOTH_MATTE]:    Data.objectFilter(oState.vendor_products, oVendorProduct => oVendorProduct.category_id === PRODUCT_CATEGORY.SMOOTH_MATTE)
                    };
                }
            },
            product_id: {
                cursor: ['state', 'app', 'product_id'],
                setState: oState => {
                    oState.product = oState.product_id ? oState.products[oState.product_id] : null;
                }
            },
            variant: {
                cursor: ['local', 'variants', sId],
                setState: oState => {
                    oState.changed = Data.isDiff(['local', 'variants', sId]);
                }
            }
        }
    }

    componentWillReceiveProps(oNextProps) {
        if (oNextProps.id !== this.props.id) {
            const {id: sId} = oNextProps;
            this.overrideCursor('variant', {
                cursor:   ['local', 'variants', sId],
                setState: oState => {
                    oState.changed = Data.isDiff(['local', 'variants', sId]);
                }
            });
        }
    }

    render() {
        const {
            instructions: oInstructions,
            backs:        oBacks,
            shipping:     oShipping,
            variant:      oVariant,
            changed:      bChanged,
            categories:   oCategories
        } = this.state;

        if (!oVariant) {
            return null;
        }

        return (
            <div className="ui form segment">
                <div className="ui header">{oVariant.title}</div>

                <div className="field">
                    <label>Instructions</label>
                    <select name="instruction_id" value={oVariant.instruction_id || ''} onChange={this.setProperty}>
                        <option value={''}>No Instructions</option>
                        {Object.values(oInstructions).map(oInstruction => <option key={oInstruction.id} value={oInstruction.id}>{oInstruction.name}</option>)}
                    </select>
                </div>

                <ShippingField shipping_id={oVariant.shipping_id} show_estimated={false} onChange={this.setProperty}/>

                <div className="field">
                    <label>Back</label>
                    <select name="back_id" value={oVariant.back_id || ''} onChange={this.setProperty}>
                        <option value={''}>No Back Side</option>
                        {Object.values(oBacks).map(oBack => <option key={oBack.id} value={oBack.id}>{oBack.name}</option>)}
                    </select>
                </div>

                <div className="field">
                    <label>Product</label>
                    <select name="vendor_product_id" value={oVariant.vendor_product_id || ''} onChange={this.setProperty}>
                        <option value={''}>No Product</option>
                        <optgroup label="Coroplast">
                            {Object.keys(oCategories[PRODUCT_CATEGORY.CORO]).map(sProductId => <option key={sProductId} value={sProductId}>{oCategories[PRODUCT_CATEGORY.CORO][sProductId].name}</option>)}
                        </optgroup>
                        <optgroup label="PVC">
                            {Object.keys(oCategories[PRODUCT_CATEGORY.PVC]).map(sProductId => <option key={sProductId} value={sProductId}>{oCategories[PRODUCT_CATEGORY.PVC][sProductId].name}</option>)}
                        </optgroup>
                        <optgroup label="13oz Smooth Matte">
                            {Object.keys(oCategories[PRODUCT_CATEGORY.SMOOTH_MATTE]).map(sProductId => <option key={sProductId} value={sProductId}>{oCategories[PRODUCT_CATEGORY.SMOOTH_MATTE][sProductId].name}</option>)}
                        </optgroup>
                    </select>
                </div>

                <div className="field">
                    <div className="ui two buttons">
                        <div className={ClassNames("ui positive icon button", {disabled: !bChanged})} onClick={this.save}><i className="save icon" /> Save</div>
                        <div className={ClassNames("ui icon button", {disabled: !bChanged})} onClick={this.reset}><i className="erase icon"/> Reset</div>
                    </div>
                </div>
            </div>
        )
    }

    setProperty = oEvent => {
        this.CURSORS.variant.set(oEvent.target.name, oEvent.target.value !== '' ? oEvent.target.value : null);
    };

    save = () => {
        const {variant: oVariant} = this.state;
        API.post(`/variants/${oVariant.id}`, oVariant, (oError, oResponse) => Data.mergeResponse(oResponse));
    };

    reset = () => {
        const {variant: oVariant} = this.state;
        this.CURSORS.variant.merge(Data.Base.get(['server', 'variants', oVariant.id]));
    };
}

