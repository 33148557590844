
import ClassNames       from "classnames";
import React            from "react";

import BaobabComponent  from './BaobabComponent';
import Backs            from './Backs';
import Instructions     from './Instructions';
import Shipping         from './Shipping';
import Settings         from './Settings';

import Data             from '../js/Data';

export default class Assets extends BaobabComponent {
    stateQueries() {
        return {
            asset: ['state', 'app', 'asset']
        }
    }

    render() {
        const {
            asset:              sAsset,
        } = this.state;

        return (
            <div>
                <div className="ui top attached tabular menu">
                    <a className={ClassNames("item", {active: sAsset === Data.ASSETS.BACKS})} onClick={this.showBacks}>Backs</a>
                    <a className={ClassNames("item", {active: sAsset === Data.ASSETS.INSTRUCTIONS})} onClick={this.showInstructions}>Instructions</a>
                    <a className={ClassNames("item", {active: sAsset === Data.ASSETS.SHIPPING})} onClick={this.showShipping}>Shipping</a>
                    <a className={ClassNames("item", {active: sAsset === Data.ASSETS.SETTINGS})} onClick={this.showSettings}>Settings</a>
                </div>
                <div className="ui attached segment">
                    {this.renderPage()}
                </div>
            </div>
        );
    }

    renderPage() {
        const {asset: sAsset} = this.state;

        switch(sAsset) {
            case Data.ASSETS.BACKS:        return <Backs />;
            case Data.ASSETS.INSTRUCTIONS: return <Instructions />;
            case Data.ASSETS.SHIPPING:     return <Shipping />;
            case Data.ASSETS.SETTINGS:     return <Settings />;
        }

        return null;
    }

    showBacks = () => {
        this.CURSORS.asset.set(Data.ASSETS.BACKS);
    };

    showInstructions = () => {
        this.CURSORS.asset.set(Data.ASSETS.INSTRUCTIONS);
    };

    showShipping = () => {
        this.CURSORS.asset.set(Data.ASSETS.SHIPPING);
    };

    showSettings = () => {
        this.CURSORS.asset.set(Data.ASSETS.SETTINGS);
    };
}