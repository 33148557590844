import ClassNames       from 'classnames';
import PropTypes        from 'prop-types';
import React            from "react";

import BaobabComponent  from './BaobabComponent';

import Data             from '../js/Data';

const STATUS = {
    NEW:      'New',
    PREPARED: 'Prepared',
    READY:    'Ready',
};

export default class OrderItemRow extends BaobabComponent {
    static propTypes = {
        id: PropTypes.string.isRequired
    };

    stateQueries() {
        const {
            id:  sId
        } = this.props;

        return {
            item_id:       {
                cursor: ['state', 'app', 'item_id'],
                setState: oState => {
                    this.setActive(oState, sId);
                }
            },
            action:        {
                cursor: ['state', 'app', 'action'],
                setState: oState => {
                    this.setActive(oState, sId);
                }
            },
            products:      ['local', 'products'],
            variants:      ['local', 'variants'],
            item:  {
                cursor: ['local', 'items', sId],
                setState: oState => {
                    oState.variant      = oState.variants[oState.item.variant_id];
                    oState.product      = oState.products[oState.variant.product_id];
                    oState.print_ready  = !!oState.item.front_url;

                    oState.missing = [];

                    if (!oState.item.front_url) {
                        oState.missing.push('Front Image');
                    }

                    if (!oState.item.back_id) {
                        oState.missing.push('Back Image');
                    }

                    if (!oState.item.vendor_product_id) {
                        oState.missing.push('Vendor Product');
                    }

                    if (!oState.item.shipping_id) {
                        oState.missing.push('Shipping Method');
                    }

                    if (!oState.item.quantity) {
                        oState.missing.push('Quantity');
                    }

                    if (!oState.item.instruction_text && !oState.item.instruction_id) {
                        oState.missing.push('Vendor Instructions');
                    }

                    oState.vendor_ready = oState.missing.length === 0;
                }
            }
        }
    }

    setActive(oState, sId) {
        oState.print_active  = oState.item_id === sId && oState.action === Data.ACTIONS.FIELDS;
        oState.vendor_active = oState.item_id === sId && oState.action === Data.ACTIONS.VENDOR;
    }

    render() {
        const {
            item:    oItem,
            item_id: sActiveItemId,
            product: oProduct,
            variant: oVariant
        } = this.state;

        if (!oItem) {
            return null;
        }

        return (
            <div className={ClassNames({active: oItem.id === sActiveItemId}, "ui fluid card")}>
                <div className="content">
                    <div className="header">
                        {oItem.quantity} x {oProduct.title}
                    </div>
                    <div className="description">
                        SKU: {oVariant.sku}
                    </div>
                    {this.renderVariantTitle()}
                </div>

                {this.renderMissing()}
                {this.renderNextButton()}
            </div>
        );
    }

    renderMissing() {
        const {missing: aMissing} = this.state;

        if (aMissing.length === 0) {
            return null;
        }

        return (
            <div className="extra content">
                <div className="meta">
                    <b>Missing:</b><br />
                    {aMissing.map(sMissing => <span key={sMissing}><a>{sMissing}</a><br /></span>)}
                </div>
            </div>
        )
    }

    renderVariantTitle() {
        const {
            variant: oVariant
        } = this.state;

        return (
            <p>
                {oVariant.title}
            </p>
        );
    }

    renderNextButton() {
        const { item: oItem } = this.state;

        if (!oItem.front_url) {
            return (
                <div className="ui bottom attached buttons">
                    {this.renderPrintButton()}
                </div>
            )
        }

        return (
            <div className="ui bottom attached buttons">
                {this.renderPrintButton()}
                {this.renderVendorButton()}
            </div>
        )
    }

    renderPrintButton() {
        const {
            item:         oItem,
            print_active: bActive
        } = this.state;

        if (bActive) {
            return <a className="ui blue icon button" onClick={this.toggleFields}>  Prepare Print <i className="arrow right icon"/></a>
        }

        if (oItem.status !== STATUS.NEW) {
            return <a className="ui green icon button" onClick={this.toggleFields}> Print Prepared <i className="print icon"/></a>
        }

        return <a className="ui icon button" onClick={this.toggleFields}> Prepare Print <i className="arrow print icon"/></a>
    }

    renderVendorButton() {
        const {
            item:          oItem,
            vendor_active: bActive
        } = this.state;

        if (bActive) {
            return <a className="ui blue icon button" onClick={this.toggleVendor}> Prepare Order <i className="arrow right icon"/></a>
        }

        if (oItem.status === STATUS.READY) {
            return <a className="ui green icon button" onClick={this.toggleVendor}> Order Prepared <i className="thumbs outline up icon"/></a>
        }

        return <a className="ui icon button" onClick={this.toggleVendor}> Prepare Order <i className="shipping icon"/></a>
    }

    toggleFields = () => {
        const {
            item:         oItem,
            print_active: bPrintActive
        } = this.state;

        if (bPrintActive) {
            this.CURSORS.item_id.set(null);
            this.CURSORS.action.set(null);
        } else {
            this.CURSORS.item_id.set(oItem.id);
            this.CURSORS.action.set(Data.ACTIONS.FIELDS);
        }
    };

    toggleVendor = () => {
        const {
            item:           oItem,
            vendor_active:  bVendorActive
        } = this.state;

        if (bVendorActive) {
            this.CURSORS.item_id.set(null);
            this.CURSORS.action.set(null);
        } else {
            this.CURSORS.item_id.set(oItem.id);
            this.CURSORS.action.set(Data.ACTIONS.VENDOR);
        }
    };
}