import ClassNames       from 'classnames';
import PropTypes        from 'prop-types';
import React            from "react";

import BaobabComponent  from './BaobabComponent';
import ShippingField    from './ShippingField';

import API              from '../js/API';
import Data             from '../js/Data';

const PRODUCT_CATEGORY = {
    CORO:           '4',
    PVC:            '119',
    SMOOTH_MATTE:   '153'
};

const STATUS = {
    NEW:        'New',
    PREPARED:   'Prepared',
    READY:      'Ready',
};

export default class OrderItem extends BaobabComponent {
    static propTypes = {
        id: PropTypes.string.isRequired
    };

    stateQueries() {
        const {id: sItemId } = this.props;

        if (!Data.Base.exists(['state', 'app', 'printed', sItemId])) {
            Data.Base.set(['state', 'app', 'printed', sItemId], {});
            Data.Base.commit();
        }

        return {
            backs:           {
                cursor: ['local', 'backs'],
                setState: oState => {
                    oState.backs = Data.objectFilter(oState.backs, oBack => oBack.status === 'Active');
                }
            },
            instructions:    {
                cursor: ['local', 'instructions'],
                setState: oState => {
                    oState.instructions = Data.objectFilter(oState.instructions, oInstruction => oInstruction.status === 'Active');
                }
            },
            shipping:        ['local', 'shipping'],
            products:        ['local', 'products'],
            vendor_products: {
                cursor:   ['local', 'vendor_products'],
                setState: oState => {
                    oState.categories = {
                        [PRODUCT_CATEGORY.CORO]:          Data.objectFilter(oState.vendor_products, oVendorProduct => oVendorProduct.category_id === PRODUCT_CATEGORY.CORO),
                        [PRODUCT_CATEGORY.PVC]:           Data.objectFilter(oState.vendor_products, oVendorProduct => oVendorProduct.category_id === PRODUCT_CATEGORY.PVC),
                        [PRODUCT_CATEGORY.SMOOTH_MATTE]:  Data.objectFilter(oState.vendor_products, oVendorProduct => oVendorProduct.category_id === PRODUCT_CATEGORY.SMOOTH_MATTE)
                    };
                }
            },
            printed:         ['state', 'app', 'printed', sItemId],
            action:          ['state', 'app', 'action'],
            item_id:         ['state', 'app', 'item_id'],
            variants:        ['local', 'variants'],
            items:           ['local', 'items'],
            item:            {
                cursor:   ['local', 'items', sItemId],
                setState: oState => {
                    oState.variant      = oState.variants[oState.item.variant_id];
                    oState.properties   = oState.item.properties ? JSON.parse(oState.item.properties) : [];
                    oState.print_ready  = !!oState.item.front_url;
                    oState.vendor_ready = !!oState.item.front_url
                                       && !!oState.item.back_id
                                       && !!oState.item.vendor_product_id
                                       && !!oState.item.shipping_id
                                       && !!oState.item.quantity
                                       && (!!oState.item.instruction_text || !!oState.item.instruction_id)

                    console.log('P.REady', oState.print_ready);
                }
            }
        }
    }

    componentWillReceiveProps(oNextProps) {
        if (oNextProps.id !== this.props.id) {
            const {id: sId} = oNextProps;

            this.overrideCursor('item', {
                cursor:   ['local', 'items', sId],
            });
        }
    }

    render() {
        const {
            action:  sAction,
            item:    oItem
        } = this.state;

        if (!oItem) {
            return null;
        }

        switch(sAction) {
            case Data.ACTIONS.FIELDS:
                return this.renderFields();

            case Data.ACTIONS.VENDOR:
                return this.renderVendor();
        }

        return null;
    }

    renderVendor() {
        const {
            backs:              oBacks,
            instructions:       oInstructions,
            categories:         oCategories,
            variant:            oVariant,
            item:               oItem
        } = this.state;

        return (
            <div className="ui form segment">
                <div className="ui header">
                    Prepare for Vendor

                    <div className="ui sub header">{oVariant.title}</div>
                    <div className="ui sub header">SKU: {oVariant.sku}</div>
                </div>

                <div className={ClassNames("required field", {error: !oItem.front_url})}>
                    <label>Front</label>
                    <textarea name="front_url" value={oItem.front_url || ''} rows="3" placeholder="Paste the Google Drive Share URL here for this sign" onChange={this.setProperty} />
                </div>

                <div className={ClassNames("required field", {error: !oItem.back_id})}>
                    <label>Back</label>
                    <select name="back_id" value={oItem.back_id || ''} onChange={this.setProperty}>
                        <option value={''}>Choose a Back Side</option>
                        {Object.values(oBacks).map(oBack => <option key={oBack.id} value={oBack.id}>{oBack.name}</option>)}
                    </select>
                </div>

                <div className={ClassNames("required field", {error: !oItem.vendor_product_id})}>
                    <label>Product</label>
                    <select name="vendor_product_id" value={oItem.vendor_product_id || ''} onChange={this.setProperty}>
                        <option value={''}>Choose a Product</option>
                        <optgroup label="Coroplast">
                            {Object.values(oCategories[PRODUCT_CATEGORY.CORO]).map(oVendorProduct => <option key={oVendorProduct.id} value={oVendorProduct.id}>{oVendorProduct.name}</option>)}
                        </optgroup>
                        <optgroup label="PVC">
                            {Object.values(oCategories[PRODUCT_CATEGORY.PVC]).map(oVendorProduct => <option key={oVendorProduct.id} value={oVendorProduct.id}>{oVendorProduct. name}</option>)}
                        </optgroup>
                        <optgroup label="13oz Smooth Matte">
                            {Object.values(oCategories[PRODUCT_CATEGORY.SMOOTH_MATTE]).map(oVendorProduct => <option key={oVendorProduct.id} value={oVendorProduct.id}>{oVendorProduct. name}</option>)}
                        </optgroup>
                    </select>
                </div>

                <ShippingField shipping_id={oItem.shipping_id} required={true} error={!oItem.shipping_id} onChange={this.setProperty}/>

                <div className={ClassNames("required field", {error: !oItem.quantity})}>
                    <label>Quantity</label>
                    <input name="quantity" type="number" value={oItem.quantity || 0} onChange={this.setProperty} min={0} step={1}/>
                </div>

                <div className={ClassNames("required field", {error: !oItem.instruction_text && !oItem.instruction_id})}>
                    <label>Instructions</label>
                    <select name="instruction_id" value={oItem.instruction_id || ''} onChange={this.setInstructions}>
                        <option value={''}>Custom Instructions</option>
                        {Object.values(oInstructions).map(oInstruction => <option key={oInstruction.id} value={oInstruction.id}>{oInstruction.name}</option>)}
                    </select>
                    <textarea name="instruction_text" placeholder="Instructions for the Vendor" value={oItem.instruction_text || ''} onChange={this.setProperty} />
                </div>

                <div className="field">
                    <a className={ClassNames("ui icon button", {green: oItem.status === STATUS.READY})} onClick={this.prepareOrder}>
                        <i className="thumbs outline up icon"/>
                        {oItem.status === STATUS.READY ? 'Ready for Vendor' : 'Confirm Item is Ready for Vendor'}
                    </a>
                </div>
            </div>
        )
    }

    setProperty = oEvent => {
        let oValue = {[oEvent.target.name]: oEvent.target.value};

        if (oEvent.target.name === 'instruction_text') {
            oValue.instruction_id = null;
        }

        this.CURSORS.item.merge(oValue)
    };

    setInstructions = oEvent => {
        const {instructions: oInstructions} = this.state;

        let oMerge = {
            instruction_id:   null,
            instruction_text: ''
        };

        if (oEvent.target.value) {
            oMerge = {
                instruction_id:   oEvent.target.value,
                instruction_text: oInstructions[oEvent.target.value].text
            };
        }

        this.CURSORS.item.merge(oMerge);
    };

    prepareOrder = oEvent => {
        const {id:           sId}          = this.props;
        const {
            vendor_ready: bVendorReady
        } = this.state;

        if (bVendorReady) {
            this.CURSORS.item.set('status', STATUS.READY);
            this.CURSORS.item_id.set(null);
            this.CURSORS.action.set(null);
            Data.Base.commit();

            if (Data.isDiff(['items', sId])) {
                const oLatestItem = Data.Base.get(['local', 'items', sId]);

                API.post(`/items/${sId}`, {
                    status:            oLatestItem.status,
                    front_url:         oLatestItem.front_url,
                    back_id:           oLatestItem.back_id,
                    instruction_id:    oLatestItem.instruction_id,
                    instruction_text:  oLatestItem.instruction_text,
                    shipping_id:       oLatestItem.shipping_id,
                    vendor_product_id: oLatestItem.vendor_product_id
                }, (oError, oResponse) => oResponse ? Data.mergeResponse(oResponse) : null);
            }
        }
    };

    printPrepared = oEvent => {
        const {id:          sId}         = this.props;
        const {
            print_ready: bPrintReady
        } = this.state;

        if (bPrintReady) {
            this.CURSORS.item.merge({status: STATUS.PREPARED});
            this.CURSORS.action.set(Data.ACTIONS.VENDOR);
            Data.Base.commit();

            if (Data.isDiff(['items', sId])) {
                const oLatestItem = Data.Base.get(['local', 'items', sId]);
                API.post(`/items/${sId}`, {
                    status:     oLatestItem.status,
                    front_url:  oLatestItem.front_url
                }, (oError, oResponse) => oResponse ? Data.mergeResponse(oResponse) : null);
            }
        }
    };

    renderFields() {
        const {
            item:       oItem,
            properties: aProperties,
            variant:    oVariant
        } = this.state;

        if (!oItem) {
            return null;
        }

        return (
            <div className="ui form segment">
                <div className="ui header">
                    Create Print

                    <div className="ui sub header">{oVariant.title}</div>
                    <div className="ui sub header">SKU: {oVariant.sku}</div>
                </div>

                <div className="required field">
                    <label>Google Drive Share URL for .ai File</label>
                    <textarea name="front_url" value={oItem.front_url || ''} rows="3" placeholder="Paste the Google Drive Share URL here for this sign" onChange={this.setProperty}/>
                </div>

                {this.renderButton()}

                {aProperties && aProperties.map((oProperty, iIndex) => {
                    switch(oProperty.name) {
                        case 'Profile Pic':
                        case 'Upload a Face':
                            return this.renderImage(oProperty, iIndex);

                        case 'File':
                            return this.renderFile(oProperty, iIndex);

                        default:
                            return this.renderField(oProperty, iIndex);
                    }

                })}
            </div>
        );
    }

    renderButton() {
        const {
            item: oItem
        } = this.state;

        return (

            <div className="field">
                <a className={ClassNames("ui icon button", {green: oItem.status !== STATUS.NEW && !!oItem.front_url, disabled: !oItem.front_url})} onClick={this.printPrepared}> <i className="arrow right icon"/>
                    Print is Ready</a>
            </div>
        )
    }

    renderField = (oProperty, iIndex) => {
        const {printed: oPrinted} = this.state;

        if (oPrinted[oProperty.name]) {
            return (
                <p key={iIndex}>{oProperty.name}: <a href="#" key={iIndex} onClick={oEvent => this.unCopy(oProperty.name, oEvent)}>{oProperty.value}</a></p>
            );
        }

        return (
            <div key={iIndex} className="field">
                <label>{oProperty.name}</label>
                <input name={oProperty.name} type="text" value={oProperty.value} readOnly={true} onClick={this.copyToClipboard}/>
            </div>
        )
    };

    renderImage = (oProperty, iIndex) => {
        return (
            <div key={iIndex} className="field">
                <label>{oProperty.name}</label>

                <a href={oProperty.value} download={true} className="ui icon button"> <img src={oProperty.value} height="100"/> <i className="download icon" /></a>
            </div>
        )
    };

    renderFile = (oProperty, iIndex) => {
        return (
            <div key={iIndex} className="field">
                <label>{oProperty.name}</label>

                <a href={oProperty.value} download={true} className="ui icon button"> {oProperty.value.split('/').pop()} <i className="download icon"/></a>
            </div>
        )
    };

    unCopy = (sField, oEvent) => {
        oEvent.preventDefault();

        this.CURSORS.printed.unset(sField);
    };

    copyToClipboard = oEvent => {
        oEvent.target.select();
        document.execCommand('copy');
        oEvent.target.focus();

        this.CURSORS.printed.set(oEvent.target.name, 1);
    };
}

