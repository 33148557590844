import ClassNames       from 'classnames';
import PropTypes        from 'prop-types';
import React            from "react";

import BaobabComponent  from './BaobabComponent';

import Data             from '../js/Data';

export default class InstructionRow extends BaobabComponent {
    static propTypes = {
        id: PropTypes.string.isRequired
    };

    stateQueries() {
        const {id: sId} = this.props;
        return {
            instruction: {
                cursor: ['local', 'instructions', sId],
                setState: oState => {
                    oState.is_archived = oState.instruction.status === 'Archived';
                }
            },
            instruction_id: {
                cursor:   ['state', 'app', 'instruction_id'],
                setState: oState => {
                    oState.is_active = oState.instruction_id === sId;
                }
            },
        }
    }

    render() {
        const {
            instruction: oInstruction,
            is_archived: bArchived,
            is_active:   bActive
        } = this.state;

        return (
            <tr className={ClassNames({active: bActive})}>
                <td className={ClassNames({disabled: bArchived})}>{oInstruction.name}</td>
                <td className="right aligned">{this.renderLink()}</td>
            </tr>
        );
    }

    renderLink = () => {
        const {
            is_active:    bActive,
            is_archived:  bArchived
        } = this.state;

        const sEdit =  bArchived ? 'Show' : 'Edit';

        return bActive
            ? <a className={ClassNames("ui icon button")} onClick={this.toggleActive}><i className="arrow left icon"/> {sEdit}</a>
            : <a className={ClassNames("ui icon button")} onClick={this.toggleActive}>{sEdit} <i className="arrow right icon"/></a>;
    };

    toggleActive = () => {
        const {
            instruction:    oInstruction,
            instruction_id: sActiveInstructionId
        } = this.state;

        this.CURSORS.instruction_id.set(sActiveInstructionId !== oInstruction.id ? oInstruction.id : null);
    }
}



