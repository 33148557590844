import React             from "react";

import BaobabComponent   from './BaobabComponent';
import ProductVariantRow from './ProductVariantRow';

import Data             from '../js/Data';

export default class ProductVariants extends BaobabComponent {
    stateQueries() {
        return {
            products: ['local', 'products'],
            variants: ['local', 'variants'],
            product_id: {
                cursor: ['state', 'app', 'product_id'],
                setState: oState => {
                    oState.product          = oState.products ? oState.products[oState.product_id] : null;
                    oState.matched_variants = oState.product  ? Object.values(oState.variants).filter(oVariant => oVariant.product_id === oState.product.id) : null;
                }
            },
            variant_id: {
                cursor:   ['state', 'app', 'variant_id'],
                setState: oState => {
                    oState.has_active = !!oState.variant_id;
                }
            }
        }
    }

    render() {
        const {
            product:          oProduct,
            matched_variants: aVariants
        } = this.state;

        if (!oProduct) {
            return null;
        }

        if (!aVariants) {
            return null;
        }

        return (
            <div className="ui segment">
                <div className="ui header">
                    {oProduct.title}

                    <div className="sub header">{oProduct.vendor}</div>
                </div>

                <table className="ui very basic table">
                    <thead>
                        {this.renderHeader()}
                    </thead>
                    <tbody>
                        {aVariants.map(oVariant => <ProductVariantRow key={oVariant.id} id={oVariant.id} />)}
                    </tbody>
                </table>
            </div>
        );
    }

    renderHeader() {
        const {has_active: bHasActive} = this.state;

        if (bHasActive) {
            return (
                <tr>
                    <th>Variant</th>
                    <th> </th>
                </tr>
            );
        }

        return (
            <tr>
                <th>Variant</th>
                <th>Instructions</th>
                <th>Shipping</th>
                <th>Back</th>
                <th>Product</th>
                <th> </th>
            </tr>
        );
    }
}