"use strict";

import React    from 'react';
import ReactDOM from 'react-dom';
import App      from './App';

import 'semantic-ui-css/semantic';
import 'semantic-ui-css/semantic.css';

import './index.css';

ReactDOM.render(
    <App />,
    document.getElementById('root')
);