import ClassNames       from 'classnames';
import PropTypes        from 'prop-types';
import React            from "react";

import BaobabComponent  from './BaobabComponent';

import Data             from '../js/Data';

export default class BackRow extends BaobabComponent {
    static propTypes = {
        id: PropTypes.string.isRequired
    };

    stateQueries() {
        const {id: sId} = this.props;
        return {
            NO_BACK_ID: ['local', 'settings', 'no_back_id', 'value'],
            back: {
                cursor: ['local', 'backs', sId],
                setState: oState => {
                    oState.is_none     = oState.back.id === oState.NO_BACK_ID;
                    oState.is_archived = oState.back.status === 'Archived';
                }
            },
            back_id: {
                cursor:   ['state', 'app', 'back_id'],
                setState: oState => {
                    oState.is_active = oState.back_id === sId;
                }
            }
        }
    }

    render() {
        const {
            back:        oBack,
            is_active:   bActive,
            is_archived: bArchived
        } = this.state;

        return (
            <tr className={ClassNames({active: bActive})}>
                <td className={ClassNames({disabled: bArchived})}>{oBack.name}</td>
                <td className="right aligned">{this.renderLink()}</td>
            </tr>
        );
    }

    renderLink = () => {
        const {
            is_none:     bNone,
            is_archived: bArchived,
            is_active:   bActive
        } = this.state;

        const sEdit = bNone || bArchived ? 'Show' : 'Edit';

        return bActive
            ? <a className={ClassNames("ui icon button")} onClick={this.toggleActive}><i className="arrow left icon"/> {sEdit}</a>
            : <a className={ClassNames("ui icon button")} onClick={this.toggleActive}>{sEdit} <i className="arrow right icon"/></a>;
    };

    toggleActive = () => {
        const {
            back:    oBack,
            back_id: sActiveBackId
        } = this.state;

        this.CURSORS.back_id.set(sActiveBackId !== oBack.id ? oBack.id : null);
    }
}



